.recipes-list {
    display: flex;
    flex-wrap: wrap; /* Permet d'organiser les recettes sur plusieurs lignes */
    gap: 1rem; /* Ajoute un espace entre les cartes */
    justify-content: left; /* Centre les cartes horizontalement */
}

.recipe-card {
    display: flex !important;
    flex-direction: row !important;
    align-items: stretch !important; /* Aligne les deux colonnes uniformément */
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 1rem;
    max-width: 500px; /* Limite la largeur maximale */
    width: 100%; /* S'adapte à la largeur disponible */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: 100px; /* Assure une hauteur minimale pour la carte */
}

/* Colonne pour l'image */
.recipe-card .image-column {
    flex: 0 0 35% !important; /* La colonne image occupe 35% de la largeur */
    max-width: 35% !important; /* Limite la largeur maximale */
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    overflow: hidden !important;
    min-height: 100px; /* Assure que la hauteur est cohérente */
}

.recipe-card .image-column img {
    width: 100% !important; /* L'image remplit la largeur */
    height: 100% !important; /* L'image remplit la hauteur */
    object-fit: cover !important; /* L'image conserve ses proportions */
    border-radius: 0; /* Supprime tout arrondi supplémentaire */
}

/* Colonne pour les informations */
.recipe-card .info-column {
    flex: 1 !important; /* La colonne informations prend tout l'espace restant */
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important; /* Aligne les éléments de haut en bas */
    padding: 0.3rem !important;
    gap: 0.3rem !important;
    overflow: hidden; /* Empêche le débordement */
}

/* Première ligne : Titre et NutriScore */
.recipe-card .recipeFirstLine {
    display: flex !important;
    justify-content: space-between !important; /* Le titre et le NutriScore sont alignés */
    align-items: center !important;
}

.card-title {
    font-weight: 800 !important;
    font-size: 1rem !important;
    color: #000; /* Couleur noire pour la lisibilité */
    margin: 0;
}

/* NutriScore */
.recipe-card .recipeNutriscore {
    height: 15px !important; /* Hauteur fixe pour le NutriScore */
    max-width: 40px !important; /* Limite la largeur maximale */
    object-fit: contain !important; /* L'image s'adapte à l'espace */
}

/* Description */
.recipe-card .card-description {
    font-size: 0.8rem !important;
    color: #333 !important;
    text-align: justify !important;
    margin-top: 0.5rem !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
}

/* Section des calories et scores */
.recipe-card .card-calories-score {
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
    margin-top: 0.5rem !important;
}

.recipe-card .card-calories-score img {
    height: 30px !important; /* Hauteur fixe */
    width: auto !important; /* La largeur s'adapte automatiquement */
}

/* Bouton d'action */
.recipe-card .card-action {
    margin-top: 0.3rem !important;
    display: flex !important;
    justify-content: center !important;
}

.recipe-card.selected {
    border: 2px solid #1C4127;
}

.recipe-card.disabled {
    pointer-events: none;
    opacity: 0.5;
}

