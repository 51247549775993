.error-container {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-container h1 {
    font-size: 65px;
}

.error-description {
    font-size: 14px;
    margin-bottom: 2rem;
}