.login-container {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input-content {
    margin: 1rem;
    display: flex;
    flex-direction: column;
}

.password-container {
    position: relative;
    margin-bottom: 3rem;
}


.input-content input {
    width: 300px !important;
    padding: 1rem;
}

.password-input {
    width: 300px !important;
    padding: 1rem;
    position: absolute
}

.password-toggle-btn {
    position: absolute;
    margin-top: .8rem;
    right: 5%;
    top: 0;
    border: none;
    background: transparent;
    height: 100%;
    cursor: pointer;
}

.forgotten-password {
    margin-top: 2rem;
}


.forgotten-password a,
.create-account-link a {
    color: #006d26;
}

.forgotten-password a:hover,
.create-account-link a:hover {
    transition: all .3 ease-in-out;
    color: #006d26;
    font-weight: 500;
}

.create-account-link {

    margin: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}