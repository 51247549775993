@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  z-index: 9999;
  position: fixed !important;
  right: 0;
  top: 0;
  width: 400px !important;
  height: 100vh;
  padding: 2rem;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: .5s ease-out 0s 1 slideInFromRight;
  overflow-y: scroll;
  overflow-x: hidden;
}

.popup-fridge-content {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.popup-fridge-instructions {
  background-color: #ddd;
  padding: 1rem;
  text-align: center;
  margin-bottom: 20px;
  width: 300px;
  font-size: 13px;
  border-radius: 2px;
}

.recipe-item-container {
  display: flex;
  width: 300px;
  margin: 1rem 4rem;
  padding: 1rem;
  border: 0.5px solid #ddd;
}

.popup-fridge-items {
  margin-bottom: 10px;
}

.popup-fridge-items-text {
  margin-left: 2rem;
  font-size: 13px;
}

.item-label {
  font-weight: bold;
}

.recipe-img-container {
  width: 60px;
  height: 60px;
  overflow: hidden;
}

.recipe-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup-fridge-actions-text {
  margin-bottom: 20px;
}

.popup-fridge-actions {
  display: flex;
  gap: 2rem;
}

.popup-fridge-items {
  flex-wrap: wrap;
  /* Allow items to wrap to the next row */
}

.recipe-item-container {
  width: 100%;
  /* Each item takes up full width */
  margin: 10px 0;
  /* Adjust spacing */
}

/*Mobile CSS*/
@media screen and (max-width: 600px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 0px;
    max-width: 500px;
    width: 100%;
  }
}