/* Popup Content Styles */
.popup-choose-product {
    width: 500px;
    z-index: 9999;

}

.popup-content {
    text-align: center;
    border: none;
    border: none;
    overflow-y: scroll;
    overflow-x: hidden;
}

.selected-products-input {
    display: flex;
    justify-content: center;

}


.select__clear-indicator {
    display: none !important;
}

.basic-multi-select {
    margin-top: 2rem;
    margin-bottom: 2rem;
    z-index: 9999;
    width: 300px;
}

.select__clear-indicator {
    display: none;
}

/* Product List Styles */
.product-recipe-list {

    list-style-type: none;
    padding: 0;
}

.products-list {

    margin-bottom: 10px;
}

.products-list-btn {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.products-list-btn:hover {
    background-color: #45a049;
}

/* Selected Products Styles */
.selected-products {
    padding: 10px;
    border-radius: 5px;
}

/* Quantity Input Styles */
.selected-products label {
    display: block;
    margin-bottom: 5px;
}

.selected-products-quantity {
    width: 300px;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 4px;
}

/* Select Button Styles */

.popup-content button {
    padding: 0.8rem 1rem !important;
}

.popup-content button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}



.popup-choose-action {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;

}