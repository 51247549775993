.footer {
    background-color: #1C4127;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    margin-top: 1rem;
}

.footer-section {
    display: flex;
    gap: 1rem;
}

.footer-section-bottom {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.footer-text p {
    text-align: justify;
    width: 65%;
    margin-bottom: 2rem
}

.footer-social,
.footer-links,
.footer-partners {
    width: 33%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.footer-social-icons,
.footer-delivery-icons {
    display: flex;
    gap: .5rem;
    margin-bottom: 2rem
}

.link {
    padding: .5rem;
    font-weight: 600
}

.link :hover {
    border-bottom: 1px solid #fff !important;
}

.link a :hover {
    color: #fff !important;
}

.delivery-logos {
    width: 50px
}

.vegan-logo {
    width: 200px;
    background-color: #fff;
}

.idf-logo,
.gagny-logo {
    width: 100px
}

.un-goals {
    display: flex;
    gap: 1rem;
}

@media screen and (max-width: 600px) {

    .footer {
        margin-top: 1rem;
        padding: 2rem
    }

    .footer-section {
        flex-direction: column;
    }

    .footer-text p {
        text-align: justify;
        width: 95%;
        margin-bottom: 2rem
    }

    .footer-social,
    .footer-links,
    .footer-partners {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }

    .footer-partners {
        justify-content: center;
    }

    .un-goals {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .footer-delivery-icons {
        margin-bottom: 0;
    }

}