/* RESET */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    overflow-x: hidden;
}

/* TYPOGRAPHY */
h1, h2, h3 {
    font-family: 'Playfair Display', serif;
}

h1 {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
}

h2, h3 {
    font-size: 24px;
    margin-bottom: 0.5rem;
}

p {
    font-size: 15px;
    line-height: 1.7;
    text-align: justify;
}

/* FLEXBOX UTILITIES */
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}


.recipes {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 1000px; /* Plus large pour desktop */
    margin: 0 auto;
    padding: 1rem;
}

.recipes-infos {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%; /* Suppression du max-width limitant */
    margin: 0 auto;
    padding: 1rem;
}

/* SHARE RECIPE */
.share-recipe {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    max-width: 600px;
    text-align: center;
}

.share-recipe input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    background-color: #fff;
}

/* IMAGE CONTAINER */
.recipes-details-img-container {
    width: 100%;
    height: 400px;
    margin: 1rem auto;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
}

.recipes-details-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.recipes-details-img-container p {
    font-size: 14px;
    color: #666;
    text-align: center;
}

/* ACTIONS */
.recipes-details-img-actions {
    width: 100%;
    margin: 1rem auto;
    text-align: right;
}

.btn-add,
.btn-update,
.edit-btn {
    font-size: 14px;
    font-weight: bold;
    color: #006d26;
    background: none;
    border: none;
    cursor: pointer;
}

.btn-add:hover,
.btn-update:hover,
.edit-btn:hover {
    text-decoration: underline;
}

.btn-update {
    margin-left: 1rem;
}

.btn-full {
    display: block; /* Ensure it behaves as a block-level element */
    width: 100%; /* Make the button full width if needed */
    max-width: 300px; /* Limit the width */
    margin: 0 auto; /* Center horizontally */
    padding: 0.75rem 1rem; /* Add padding for better appearance */
    font-size: 16px; /* Make the text more prominent */
    color: #fff; /* Text color */
    background-color: #006d26; /* Button background */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor for interactivity */
    text-align: center; /* Center the text inside the button */
}

.btn-full:hover {
    background-color: #004d1a; /* Slightly darker shade on hover */
}

/* Centering the container holding .btn-full */
.recipes-products-update-nutri-facts {
    display: flex;
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    margin-top: 1rem; /* Add space from elements above */
    height: auto; /* Adjust height as needed */
    text-align: center; /* Center-align the content */
}

/* NUTRITION FACTS */
.nutri-facts{ 
    width: 100%;
}
.recipes-infos-nutri-facts {
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.nutri-facts-title {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
}

.nutri-facts-title span {
    font-size: 12px;
    margin-bottom: 1rem;
}

/* POPUP ACTIONS */
.recipes-infos-action {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.recipes-infos-action button {
    font-size: 14px;
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: #ff5e57;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.recipes-infos-action button:hover {
    background-color: #ff4242;
}

.recipes-details-nutriscore img {
    width: 100px; /* Adjust this value as needed */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure it doesn't overflow its container */
    display: block; /* Remove inline spacing around the image */
    margin: 0 auto; /* Center the image within its container */
}

/* RESPONSIVE DESIGN */
@media screen and (max-width: 600px) {
    .recipes-details-img-container {
        width: 100%;
        height: 200px;
    }

    .recipes {
        padding: 0.5rem;
    }

    .recipes-infos {
        padding: 0.5rem;
        max-width: 100%;
    }

    .share-recipe input {
        width: 100%;
    }

    .recipes-infos-action button {
        font-size: 12px;
        padding: 0.5rem;
    }

    .btn-full {
        display: block; /* Ensure it behaves as a block-level element */
        width: 100%; /* Make the button full width if needed */
        max-width: 300px; /* Limit the width */
        margin: 0 auto; /* Center horizontally */
        padding: 0.75rem 1rem; /* Add padding for better appearance */
        font-size: 16px; /* Make the text more prominent */
        color: #fff; /* Text color */
        background-color: #006d26; /* Button background */
        border: none; /* Remove border */
        border-radius: 5px; /* Rounded corners */
        cursor: pointer; /* Pointer cursor for interactivity */
        text-align: center; /* Center the text inside the button */
    }
    
    .btn-full:hover {
        background-color: #004d1a; /* Slightly darker shade on hover */
    }
    
    /* Centering the container holding .btn-full */
    .recipes-products-update-nutri-facts {
        display: flex;
        justify-content: center; /* Horizontal centering */
        align-items: center; /* Vertical centering */
        margin-top: 1rem; /* Add space from elements above */
        height: auto; /* Adjust height as needed */
        text-align: center; /* Center-align the content */
    }
}
