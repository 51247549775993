.flex-center {
    display: flex;
    flex-direction: column; /* Les enfants seront empilés verticalement */
    align-items: center; /* Centrer horizontalement */
    justify-content: center; /* Centrer verticalement */
    text-align: center; /* Centrer le texte */
    gap: 20px; /* Espacement entre les enfants */
    width: 100%;
}

.client-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.client-title {
    margin-top: 3rem;
}

.client-action {
    margin-bottom: 2rem;
}

.btn-pikizy {
    margin-top: 1rem;
}

.client-infos-container {
    width: 100%;
    border: .5px solid #ddd;
    margin: 3rem 2rem;
    display: flex;
    flex-direction: column;
    gap: .5rem
}

.client-infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    padding: 0rem 8rem;

}

.client-infos-perso {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.client-infos-picture {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}

.client-infos-picture:hover {
    cursor: pointer;

}

.client-picture-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -2rem;
    background-color: #006d26;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    position: absolute;
    top: 25%;
    left: 60%;
}

.client-picture {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.picture-mobile {
    display: none;
}

.client-infos-picture-mobile {
    display: none;
}

.client-infos-allergies {
    gap: .5rem;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;

}

.client-infos-allergies li {
    list-style: none;
}

.client-allergies-actions {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.client-infos-action {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.client-orders-title {
    margin-top: 3rem;
}

.client-orders {
    width: 100%;
    margin-bottom: 3rem;
}

.client-orders-title {
    margin-bottom: 2rem;
}

.mobile-client-order-list {
    display: none;
}

.client-manage-subscription {
    margin: 2rem
}


.client-subscription {
    width: 100%
}


.client-subscription-title,
.client-manage-subscription-title {
    margin: 1.5rem;
    width: 377px;
    text-align: center;
}

.client-subscription-description,
.client-manage-subscription-description {
    width: 615px;
    text-align: justify;
    margin-bottom: 1.5rem;
}




/*Mobile CSS*/
@media screen and (max-width: 600px) {
    .client-infos {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: .5rem;
        width: 100%;
        padding: .5rem .5rem;

    }

    .client-infos-perso {
        padding: 1rem 0;
        align-items: center;
    }

    .client-age {
        display: none;
    }

    .client-infos-allergies {
        width: 100%;
        background-color: #e9e9e9;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        border-radius: 3px;
        margin-bottom: 2rem;
        align-items: center;
    }


    .client-infos-allergies h2 {
        margin-bottom: .5rem;

    }

    .client-infos-picture {
        display: none;
    }

    .picture-mobile {
        display: inline;
        width: 100%;
        margin-top: 1rem;
    }

    .client-infos-picture-mobile {
        display: inline;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
    }

    .client-picture {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }



    .desktop-client-order-list {
        display: none;
    }

    .mobile-client-order-list {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: .5rem;

    }

    .order-info {
        margin-right: 1rem;
        display: flex;
        gap: 1rem
    }

    .order-info-price span {
        margin-right: 1rem
    }



    .order-summary {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: .3rem 0;
    }


    .typography {
        width: 100%;
    }

    .mobile-payment-paragraph {
        width: 100%;
    }

    .mobile-payment-span {
        font-weight: bold;
    }

    .client-subscription {
        margin: 1rem;
    }

    .client-subscription-description {
        width: 355px;
        text-align: justify;
        margin-bottom: 1.5rem;
    }

    .stripe-pricing {
        margin-bottom: 2rem;
    }



}


/* Tablet CSS */
@media screen and (min-width: 601px) and (max-width:1024px) {


    .client-subscription-description,
    .client-manage-subscription-description {
        width: 535px;
        text-align: justify;
        margin-bottom: 1.5rem;
    }
}

/* Style des paragraphes */
.subscription-info p {
    margin: 10px 0;
    font-size: 16px; /* Taille confortable pour une lecture fluide */
    font-weight: 400; /* Poids de police standard */
    color: #333; /* Couleur du texte bien contrastée */
    line-height: 1.6; /* Espacement des lignes pour une meilleure lisibilité */
}

/* Ligne de séparation entre paragraphes */
.subscription-info p:not(:last-child) {
    border-bottom: 1px solid #ddd; /* Ligne fine et subtile */
    padding-bottom: 10px; /* Espacement sous la ligne */
}

/* Lien stylisé */
.subscription-info a {
    color: #007bff; /* Couleur du lien (bleu) */
    text-decoration: none; /* Supprime le soulignement par défaut */
    font-weight: 500; /* Rend le lien légèrement plus épais */
}

.subscription-info a:hover {
    text-decoration: underline; /* Ajoute un soulignement au survol */
    color: #0056b3; /* Couleur légèrement plus foncée au survol */
}