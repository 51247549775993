.recipe-list-mobile {
    max-width: 100%;
    padding: 0 5px; /* Reduced padding to prevent overflow */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    
}

.recipe-mobile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    max-width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.mobile-recipe-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    max-width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.recipe-mobile-header > h2 {
    margin: 1em;
}

.recipe-mobile-header > button {
    margin: 0 1em;
}

.recipe-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    max-width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.mobile-recipe-card {
    max-width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.mobile-recipe-parts {
    margin-bottom: 1.5rem;
}

.mobile-recipe-parts h2 {
    margin-left: 1.5rem;
}

.divison {
    width: 100%;
}
.search {
    width: 100%;
    margin-bottom: 1rem;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: .5rem;
    background-color: #fff;
    padding: 1rem;
}

.filters-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: .5rem;
    background-color: #fff;
}

 /* Example of adjusting specific elements */
 .MuiCard-root, .division, .filters, .filters-mobile {
    width: 100%; /* Ensure cards and other elements are 100% of the screen width */
  }



  .css-trhure-JoyCard-root {
    width: 100% !important; /* Override the fixed 320px width */
    max-width: 100%; /* Ensure it doesn’t exceed the container width */
  }

  .css-mvwmr4-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  /* Styles pour mobile */
.recipe-list-mobile {
    padding: 10px;
    font-size: 14px;
  }
  
  .recipe-list-mobile .btn-add {
    width: 100%;
  }
  
  /* Styles pour desktop */
  .recipe-list-desktop {
    padding: 20px 50px;
    font-size: 16px;
  }
  
  .recipe-list-desktop .btn-add {
    width: auto;
  }
  