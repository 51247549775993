.order-validation-container {
    max-width: 858px; /* Limite maximale */
    width: 100%;      /* S'adapte au parent */
    margin: 0 auto;   /* Centrage horizontal */
    padding: 1rem;    /* Espacement interne */
}

.order-validation-items {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Espacement vertical */
    align-items: center; /* Centrer horizontalement */
    max-width: 858px; /* Limite maximale */
    margin: 0 auto;
    padding: 1rem;
}

.order-validation-title {
    margin-top: 2rem;
}

.order-validation-action {
    margin: 1.5rem 0 1.5rem 0;
}

.order-validation-total {
    padding: 3rem 0;
    width: 858px;
    display: flex;
    flex-direction: column;

}

.order-validation-total-ht,
.order-validation-tva,
.order-validation-ttc {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.order-validation-ttc {
    color: rgb(50, 89, 62)
}

.ttc-price {
    font-weight: 600;
    color: rgb(50, 89, 62)
}

.order-item-container {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column; /* ou 'row' selon le design souhaité */
    justify-content: space-around;
    align-items: center; /* Centrer les éléments sur l'axe transversal */


}

.order-item-title-container {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.order-item-text {
    width: 70%;
    display: flex;
        flex-direction: row;
        align-items: center;

}

.order-item-description {
    margin-left: 2rem;
    width: 70%;
    text-align: justify;
}

.order-item-quantity {
    margin-left: 2rem;

}


.order-item-img-container {
    aspect-ratio: 1;   /* Maintient un carré */
    max-width: 70px;   /* Limite maximale */
    border-radius: 5px;
    overflow: hidden;
}

.order-item-img {
    width: 100%;      /* S'adapte au conteneur */
    height: auto;     /* Conserve les proportions */
    object-fit: cover;
}

.order-condition-container {
    width: 858px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #e8e7e7;
    padding: 2rem;
}

.order-input-checkbox {
    margin-right: 1rem;
    margin-top: .5rem;
    cursor: pointer;
}


.order-condition-text {
    flex: 1;
    text-align: justify;
}

.order-validation-checkout {
    padding: 0.5rem;
    gap: 0.5rem;
}

/* ------------------------------------------------ PREORDERS --------------------------------------------------- */
/*
/* ----------------- PREORDER-DATE ------------------- */

.preorder-date {
    margin: 2rem 0;
    padding: 2rem;
    background-color: #eaeaea;
}

.preorder-date-title {
    margin-bottom: 1rem;
}

.preorder-date-description {
    text-align: justify;
}

.preorder-date-description span {
    font-weight: bold;
}


.preorder-date-pickers {
    display: flex;
    justify-content: space-around;
    padding: 2rem;
}

.delivery-type {
    display: flex;
    gap: 1rem;
    margin: 2rem 1rem;
}

.delivery-card-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* ----------------- PREORDER-ADDRRESSES ------------------- */

.order-addresses {
    max-width: 858px; /* Limite maximale */
    width: 100%;      /* S'adapte au conteneur parent */
    margin: 0 auto;   /* Centrage horizontal */
    padding: 2rem;    /* Espacement intérieur */
}

.order-addresses-actions {
    text-align: right;
}

.address-radio>.css-ahj2mt-MuiTypography-root {
    color: #333 !important;
}

.delivery-type {
    display: flex;
}

.order-validation-total,
.order-condition-container {
    max-width: 858px; /* Limite maximale */
    width: 100%;      /* S'adapte à l'écran */
    margin: 0 auto;   /* Centrage */
    padding: 2rem;    /* Espacement interne */
}


/*Mobile CSS*/
@media screen and (max-width: 600px) {

    .order-validation-title {
        padding: 0 2rem;
        text-align: center;
    }

    .order-item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .order-item-title-container {
        margin-left: 1rem;
        margin-right: 1rem;
        padding: 0 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .order-item-text {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .order-item-description {
        margin-left: .5rem;
        width: 95%;
        text-align: justify;
    }

    .order-item-img-container {
        aspect-ratio: 1;   /* Maintient un carré */
    max-width: 70px;   /* Limite maximale */
    border-radius: 5px;
    overflow: hidden;
    }

    .order-item-img {
        width: 100%;      /* S'adapte au conteneur */
    height: auto;     /* Conserve les proportions */
    object-fit: cover;
    }

    .order-item-quantity {
        margin: 1rem 0;

    }

    .order-validation-total {
        padding: 3rem;
        width: 100%;

    }

    .order-validation-total h2 {
        font-size: 18px;

    }

    .order-validation-total-ht,
    .order-validation-tva,
    .order-validation-ttc {
        align-items: center;
    }

    .order-validation-checkout {
        display: flex;
        flex-direction: column;
    }

    .order-addresses-title {
        margin-bottom: 1rem;
    }


    .order-addresses-add {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .order-addresses-select legend {
        margin-bottom: 1rem;
    }

    .delivery-type {
        display: flex;
        flex-direction: column;
    }
    .order-validation-container {
        padding: 0.5rem;
        width: auto; /* Assure que le conteneur s'adapte à la largeur de l'écran */
    }

    .order-item-container, .order-item-description, .order-validation-total {
        width: 100%; /* Utilisez 100% de la largeur pour les éléments internes */
    }
    .order-addresses {
        max-width: 100%; /* Utilise toute la largeur de l'écran */
        padding: 1rem;   /* Ajoute de l'espacement */
    }
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}



/* Tablet CSS */
@media screen and (min-width: 601px) and (max-width:1024px) {
    .order-validation-total {
        padding: 3rem 0rem;
        width: 600px;
        display: flex;
        flex-direction: column;

    }

    .order-condition-container {
        width: 600px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        background-color: #e8e7e7;
        padding: 2rem;
    }

    .order-addresses {
        max-width: 858px; /* Limite maximale */
        width: 100%;      /* S'adapte au conteneur parent */
        margin: 0 auto;   /* Centrage horizontal */
        padding: 2rem;    /* Espacement intérieur */
    }

    .order-validation-date {
        width: 600px;
        margin: auto;
    }
    .order-validation-container, .order-validation-total, .order-condition-container, .order-addresses, .order-validation-date {
        width: 100%; /* Assurez-vous que la largeur s'adapte à la taille de l'écran de la tablette */
        max-width: 600px; /* Une largeur maximale pour éviter que les éléments ne soient trop étirés */
        margin: auto; /* Centrage horizontal */
    }
}