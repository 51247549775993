@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 999;
}

.infos-modal {
    background-color: #fff;
    z-index: 999999999999;
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    height: 100vh;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: 0.5s ease-out 0s 1 slideInFromRight;
    overflow-y: scroll;
    overflow-x: hidden;
}

.infos-modal-title {
    margin-bottom: 1rem;
}

.infos-modal-descriptions {
    text-align: justify;
    margin-bottom: 2rem;
}

.edit-modal-form,
.edit-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
}

.edit-field {
    display: flex;
    flex-direction: column;
}

.edit-input {
    padding: 1rem;
    width: 300px;
    border: 1px solid #DADADA;
    border-radius: 5px;
}

.infos-modal-action {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 50px;
}

.order-items-list {
    list-style: none;
    margin-bottom: 2rem;
}

.items-list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ingredient-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 2rem;
}

.ingredient-item {
    display: flex;
}

.ingredients-legend {
    border: 1px solid #DADADA;
    border-radius: 5px;
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 2rem;
}

@media screen and (max-width: 600px) {
    .infos-modal {
        width: 100vw;
        height: 100vh;
        padding: 1rem;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: 0.5s ease-out 0s 1 slideInFromRight;
    }

    .edit-input {
        width: 100%;
    }

    .ingredient-list {
        gap: 0.3rem;
        justify-content: center;
        width: 100%;
    }

    .ingredients-legend {
        flex-wrap: wrap;
        gap: 0.3rem;
        justify-content: center;
    }

    .infos-modal-action {
        width: 100%;
    }
}
