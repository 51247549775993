.home {
    display: flex;
    justify-content: space-between;
    height: auto; /* Laissez le contenu déterminer la hauteur */
    overflow: visible; /* Autorisez le contenu à s'étendre */
}

.recipes {
    flex: 1;
    max-height: none; /* Laissez le contenu s'ajuster */
  overflow-y: auto; /* Ajoutez un scroll interne uniquement si nécessaire */
  max-width: 900px;
}

.side-cart {
    height: 100vh;
    width: 375px;
    border-left: .5px solid #ddd;
    overflow-y: scroll;
}


/*Mobile CSS*/
@media screen and (max-width: 600px) {
    .side-cart {
        display: none;
    }
}

/*Tablet CSS*/
@media screen and (min-width: 601px) and (max-width:1024px) {
    .side-cart {
        display: none;
    }
}