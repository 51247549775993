.admin-panel {
    display: flex;
}

.admin-dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}





.admin-actions {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.admin-actions button {
    margin: 1rem;
}

.admin-infos {
    margin-top: 10rem;
    align-items: center;
}

.admin-infos-number {
    font-weight: bold;
    font-size: 70px;
}

.admin-infos-orders,
.admin-infos-livreurs,
.admin-infos-ca {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* --------------- ADMIN NAVBAR --------------- */
.admin-navbar {
    background-color: #fff;
    height: 90vh;
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.3) 10px 10px 20px -20px;
    color: rgb(108, 108, 108);
    padding-top: 1rem;
}

.admin-navbar-links {
    padding: 10px 20px; /* Ajoute du padding pour un meilleur espacement */
    font-weight: bold;
    display: flex;
    align-items: center;
    border: none; /* Supprime les bordures */
    background-color: transparent; /* Rend le fond transparent */
    cursor: pointer; /* Change le curseur pour indiquer qu'il s'agit d'un bouton */
    transition: background-color 0.3s ease; /* Ajoute une animation au survol */
}


button:hover {
    background-color: #f0f0f0; /* Change légèrement le fond au survol */
    border-radius: 5px; /* Ajoute un léger arrondi */
}

.admin-navbar-icons {
    margin-right: 1rem;
}

.admin-title {
    margin: 3rem 0;
    padding-left: 3rem;

}

.admin-title h1 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 20px;
    color: rgb(75, 75, 75);
}

.admin-navbar-btn {
    margin-top: 1rem;
    padding: .5rem 1rem;
    background-color: transparent;
    border: 1px solid rgb(75, 75, 75);
    border-radius: 4px;
    color: rgb(75, 75, 75);
    margin-left: 3rem;
    font-weight: bold;
}

.admin-navbar-btn:hover {

    border: 1px solid #006d26;
    color: #006d26;
    cursor: pointer;
}

.btn-navbar {
    margin-top: 1rem;
    padding: .5rem 1rem;
    background-color: transparent;
    border: 1px solid rgb(75, 75, 75);
    border-radius: 4px;
    color: rgb(75, 75, 75);
    margin-left: 3rem;
    font-weight: bold;
    font-size: 12px;
}

.btn-navbar:hover {

    border: 1px solid #006d26;
    color: #006d26;
    cursor: pointer;
}

/* --------------- ADMIN MOBILE--------------- */
.admin-mobile-header {
    background-color: #1C4127;
    color: #fff;
    height: 100px;
}

.admin-mobile-header h2 {
    padding: 1rem;
    padding-bottom: 0;
}

.admin-mobile-actions {
    margin-top: 2rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-top: 2rem;

}

.impact-cards {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
}

@media screen and (min-width: 601px) and (max-width:1024px) {
    .admin-title {
        padding-left: 2rem;
    }

    .admin-navbar-links {
        padding-left: 2rem;
        padding-bottom: 1.5rem;
        font-weight: bold;
        display: flex;
        align-items: center;
    }

    .admin-navbar {

        height: 100dvh;

    }

    .admin-dashboard {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .impact-cards {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 601px) {


    .impact-cards {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 2rem;
        width: 100%
    }
}

/* CSS général pour .dashboard-client */
.dashboard-client {
    display: flex;         /* On bascule en mode flex container */
    flex-direction: row;   /* Par défaut en row, mais on le précise au besoin */
    width: 100%;
}

/* La barre de navigation */
.client-navbar, 
.admin-navbar {  /* votre .client-navbar rendue dans le DOM a la classe .admin-navbar */
    width: 20%;           /* largeur fixe ou adaptable */
    flex-shrink: 0;         /* évite que la nav bar se réduise sous certaines conditions */
}

/* Le contenu principal */
.admin-dashboard-content {
    flex: 1;                /* le contenu occupe tout l'espace restant */
    padding: 2rem;          /* un peu de padding pour l'esthétique */
}