@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjusted opacity for better contrast */
    z-index: 999999;
}

.popup-menu {
    background-color: #fff;
    z-index: 999999;
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    max-width: 100%; /* Allows popup to fit on smaller screens */
    height: 100vh;
    min-height: 100vh;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px; /* Increased shadow for better visibility */
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: slideInFromRight 0.5s ease-out;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

.edit-modal-title {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    text-align: center;
}

.popup-menus-content {
    z-index: 999;
    width: 100%;
}

.popup-menu-actions {
    margin: 0.3rem 0;
    display: flex;
    flex-direction: row; /* Ensures buttons are side by side */
    justify-content: center;
    gap: 0.3rem;
    width: 100%;
}

.btn-full, .btn-border {
    flex: 1; /* Ensures equal spacing for both buttons */
    padding: 0.3rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 8px;
}

.btn-full {
    background-color: #006d26;
    color: #fff;
    border: none;
    cursor: pointer;
}

.btn-border {
    background-color: transparent;
    border: 2px solid #006d26;
    color: #006d26;
    cursor: pointer;
}

.popup-menus-header {
    position: sticky; /* Permet de fixer tout le conteneur en haut */
    top: 0; /* Position fixe en haut */
    background-color: white; /* Fond pour masquer les éléments derrière */
    z-index: 10; /* S'assure que ce bloc est au-dessus des autres éléments */
    padding: 0.3rem; /* Espacement interne */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Ombre pour séparation visuelle */
    border-bottom: 1px solid #ddd; /* Ligne de séparation */
    box-sizing: border-box; /* Inclut les bordures dans les dimensions */
    width: 100%;
}


.recipe-catalog {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center; 
    gap: 1rem; 
    width: 100%;
    padding: 1rem 0;
    box-sizing: border-box;
}

.popup-menu-recipe-card {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.3s, border 0.3s;
}

.popup-menu-recipe-card:hover {
    transform: scale(1.05);
}

.popup-menu-recipe-card.selected {
    border: 2px solid #004d00;
    transform: scale(1.05);
}

/* Responsive adjustments */
@media screen and (max-width: 600px) {
    .popup-menu {
        width: 100vw;
        height: 100vh;
        padding: 0.3rem;
    }
}
