.popup-welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    border: none;
    border-radius: 5px;
    position: fixed;
    top: 30%;
    right: 30%;
    background-color: #fff;
    width: 40vw;
    height: fit-content;
    padding: 3rem;
    z-index: 9999999;
}

.welcome-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0);
    opacity: .7;
    z-index: 999;
    backdrop-filter: blur(5px);
}


.popup-welcome-text {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

/*Mobile CSS*/
@media screen and (max-width: 600px) {
    /* .popup-content {
        width: 100% !important;
    } */

    .popup-welcome {
        position: unset;
        width: 100%;
    }
}