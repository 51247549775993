.recipes-admin-title {
    margin: 1rem 0;
}

.recipes-admin-action {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.recipes-admin-action button {
    margin-bottom: 2rem;
}

/* Responsive Mobile
@media screen and (max-width: 600px) {
    .recipes-admin-title {
        margin: 1rem 0;
    }
  } */