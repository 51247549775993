@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}



.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0);
    opacity: .1;
    /* Fond semi-transparent noir */
    z-index: 999;
    /* Pour s'assurer que l'overlay est au-dessus du modal */
}


.edit-modal {
    background-color: #fff;
    z-index: 9999;
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    height: 100vh;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: .5s ease-out 0s 1 slideInFromRight;
}

.edit-modal-title {
    margin-bottom: 2rem;
    color: #000
}

.edit-modal-form,
.edit-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
}

.edit-field {
    display: flex;
    flex-direction: column;
}

.edit-input {
    padding: 1rem;
    width: 300px;
    border: 1px solid #DADADA;
    border-radius: 5px;

}

.edit-modal-action {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 300px;
}

@media screen and (max-width: 600px) {
    .edit-modal {
        z-index: 99999;
        width: 100vw;
    }

}