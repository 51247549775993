.my-popup {
    /* Styles spécifiques pour isoler le contenu du pop-up */
    border: none;
    text-align: center;
    padding: 1rem;
    max-height: 100vh;
    overflow-y: auto;
    z-index: 100000;
}
.popup-content {
    margin-top: 50px;
    width: 60%!important;
    z-index: 99999999;
}

element.style {
    inset: 0;
}

.popup-confirm-content {
    border: none;
    text-align: center;
    padding: 1rem;
    padding-top: 5rem;
}



.reactjs-popup-overlay {
    backdrop-filter: blur(5px);
}

.popup-text {
    margin-bottom: 2rem;
}



.popup-actions button {
    margin-right: 1rem;
    margin-left: 1rem;
}

/*Mobile CSS*/
@media screen and (max-width: 600px) {
    .popup-content {
        margin-top: 60px;
        width: 100% !important;
    }
}