.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0);
    opacity: .1;
    /* Fond semi-transparent noir */
    z-index: 999;

}


.infos-modal {
    background-color: #fff;
    z-index: 999999999999;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: .5s ease-out 0s 1 slideInFromRight;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 40vw;
    max-width: 100%;
}

.popup-update-content h2 {
    text-align: center
}

.popup-update-form {
    display: flex;
    flex-direction: column;
    padding: 0rem 2rem;
}

.popup-update-input {
    width: 100%;
    border-radius: 5px;
    padding: .5rem;
    border: .5px solid #ddd;
}

.update-allergies {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 2rem;
}

.allergies-fieldset {
    align-items: center;
    padding-bottom: 1.2rem;
    margin-right: 11px;

}


.allergies-fieldset div {

    list-style: none;

}


/*.allergies-fieldset [type="checkbox"],
.allergies-fieldset [type="radio"] {
    display: none;
}

.allergies-fieldset label {
    width: 30px;
    cursor: pointer;
    padding: .5rem;
    margin-right: .5rem;
    border: .5px solid #ddd;
    border-radius: 5px;
}

.allergies-fieldset input[type="checkbox"]+label:hover {
    border: .5px solid #006d26;
    color: #006d26
}

.allergies-fieldset input[type="checkbox"]:checked+label {
    border: 1px solid #006d26;
    color: #006d26;
    font-weight: 500;
}

.allergies-fieldset input[type="checkbox"]:checked+label:hover {
    background: rgba(0, 128, 128, 0, .7);
}

.allergies-legend {
    padding: 20px 0px
}*/


/*Mobile CSS*/
@media screen and (max-width: 600px) {
    .allergies-fieldset label {
        width: 30px;
        cursor: pointer;
        padding: .3rem;
        margin-right: 0rem;
        border: .5px solid #ddd;
        border-radius: 5px;
    }
    .infos-modal {
        width: 100vw;
        padding: 2rem;
    }
    /* .form{
        width: 100%;
        display: contents;
    } */
     .popup-update-form{
        width: min-content;
     }
    .popup-actions {
        margin-bottom: 1rem;
        display: flex;
        justify-content: center; 
        gap: 10px; 
    }
}


