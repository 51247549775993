.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
}

.popup-content {
    background-color: #fff;
    width: 100%;
    max-width: 600px; /* Largeur maximale pour la pop-up */
    max-height: 100vh; /* Hauteur maximale */
    overflow-y: auto; /* Scroll vertical pour le contenu de la pop-up */
    padding: 2rem;
    border-radius: 10px;
}

.popup-title {
    text-align: center;
}

.popup-confirm-content {
    max-height: 100%; /* Limite à 80% de la hauteur de la fenêtre */
    overflow-y: auto; /* Active le scroll vertical si nécessaire */
}

.popup-recipe-form form {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 1rem;
    text-align: left;

}

.popup-recipe-form textarea {
    padding: .5rem;
}

.popup-recipe-name,
.popup-recipe-barcode,
.popup-recipe-description,
.popup-recipe-price,
.popup-recipe-partId,
.custom-file-upload,
.popup-recipe-visibility,
.popup-recipe-product,
.popup-recipe-portions {
    width: 100%;
    max-width: 300px; /* limite optionnelle */
    padding: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.popup-recipe-product {
    padding: 0;
    width: 300px;
    color: green;
}


.price-input {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.price-input p {
    margin-right: .5rem;
}

.price-input input {
    width: 4rem;
    text-align: center;
    border: none;
    padding: .5rem 1rem;
    margin: 0 .5rem;
}

.price-input-actions {
    display: flex;
    align-items: center;
}

.price-input-actions button {
    border: none;
    background-color: transparent;
    color: rgb(50, 89, 62);
    padding: 1rem 0;
    cursor: pointer;
}

.visibility-toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.partId {
    width: 100%;
    padding: 0.5rem 1rem !important;
}

.custom-file-upload {
    display: flex;
    align-items: center;
    height: 50px;
    gap: 1rem;
    padding: 0.5rem 1rem !important;
    color: gray !important;
    border-color: gray !important;
    margin-bottom: 1rem;
}

.custom-file-upload:hover {

    color: rgb(50, 89, 62) !important;
    border-color: rgb(50, 89, 62) !important;

}


input[type="file"] {
    display: none;
}

@media screen and (max-width: 600px) {
    .popup {
        padding: 0; /* Supprime tout padding externe */
    }

    .popup-content {
        width: 100%; /* Occupe presque toute la largeur */
        max-height: 100vh; /* Garde une hauteur maximale pour le scrolling */
    }
}