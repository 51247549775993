.reset-password-container {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.reset-password-mail {
    margin: 1rem;
    gap: 1rem;
}

.reset-password-container h2 {
    margin-bottom: 2rem;
}

.reset-password-input {
    width: 300px !important;
    position: absolute
}

.password-container {
    position: relative;
    margin-bottom: 3rem;
}

.input-content input {
    width: 300px !important;
    padding: 1rem;
}

.input-content {
    margin: 0;
    margin-bottom: 1rem;
}

.reset-password-action {
    margin-top: 2rem;
}

.password-toggle-btn {
    position: absolute;
    margin-top: .8rem;
    right: 5%;
    top: 0;
    border: none;
    background: transparent;
    height: 100%;
    cursor: pointer;
}