@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500;600&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  background-color: #ffffff;
  height: auto; /* Permet au body de s'ajuster à la hauteur du contenu */
  overflow-x: hidden; /* Évite les scrollbars horizontaux */
  min-height: 100vh; /* S'assure que le body couvre toute la hauteur de l'écran */
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Fait en sorte que l'app couvre toujours l'écran */

}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}

h1 { font-size: 28px; margin-bottom: 1rem; }
h3 { font-size: 18px; margin-bottom: 1rem; }

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

a:hover { color: #006d26; }

/* Scrollbar */
*::-webkit-scrollbar { width: 6px; }
*::-webkit-scrollbar-track { border-radius: 8px; background-color: #e7e7e7; }
*::-webkit-scrollbar-thumb { border-radius: 8px; background-color: #006d26; }

/* Container */
.container {
  flex: 1; /* Fait grandir le contenu principal pour occuper l'espace disponible */
  max-width: 100%;
  margin: 0;
  margin-top: 4%;
  overflow: auto; /* Permet au contenu de s'étendre sans bloquer */
}

/* Utility Classes */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Footer */
.footer {
  background-color: #1C4127;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-top: auto; /* Pousse le footer vers le bas lorsque le contenu est court */
  position: relative; /* Évite qu'il devienne "fixe" ou bloque le contenu */
  overflow: visible;
}

/* Buttons */
.btn-full, .btn-full-mobile {
  padding: 0.8rem 3rem;
  background-color: rgb(50, 89, 62);
  color: #fff;
  border: 1px solid rgb(50, 89, 62);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  /* margin: 1em 0em 0em 0em; */
}

.btn-full:hover, .btn-full-mobile:hover {
  background-color: #006d26;
  border-color: #006d26;
  cursor: pointer;
}

.btn-border {
  padding: 0.8rem 3rem;
  border: 1px solid rgb(50, 89, 62);
  color: rgb(50, 89, 62);
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
}

.btn-border:hover {
  cursor: pointer;
  border-color: #006d26;
}

.btn-disabled, .btn-disabled-mobile {
  background-color: #ddd;
  border: 10px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #006d26;
}

/* Responsive Utilities */
@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden; /* Empêche les scrollbars horizontales */
    margin-top: 50px;
  }

  .division {
    width: 100%;
    margin: 0 auto;
  }

  .btn-full, .btn-border {
    width: calc(100% - 20px); /* Respecte le padding horizontal */
    padding: 0.3rem;
  }

  body, .app, .container, .footer {
    -webkit-overflow-scrolling: touch; /* Active un scroll fluide sur iOS */
  }

  .container, .footer {
    overflow-y: auto; /* Assurez-vous que le contenu peut défiler si nécessaire */
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .container {
    width: 100%;
    max-width: 1200px; /* Ajoute une limite pour les écrans larges */
    padding: 0 10px; /* Ajoute un padding pour éviter que le contenu touche les bords */
  }
}
