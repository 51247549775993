.recipes-nav {
    position: fixed; /* Fixée en bas de l'écran */
    bottom: 0; /* Place la nav en bas */
    z-index: 100; /* Assure qu'elle est au-dessus des autres éléments */
    background-color: white;
    padding: 1rem; /* Espacement interne */
    width: 100%; /* S'étend sur toute la largeur de l'écran */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 0.5px solid #ddd; /* Ajoute une bordure pour séparer des recettes */
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1); /* Ombre pour démarquer */
  }
  
  /* Contenu principal (pour éviter que la recipes-nav chevauche) */
  .css-j204z7-MuiFormControlLabel-root {
    color: white !important; /* Remplace le bleu par blanc */
  }
  
  /* Liens dans .recipes-nav */
  .recipes-nav-links {
    margin-top: 0;
    width: 50%;
    display: flex;
    justify-content: space-around;
  }
  
  /* Précommande */
  .recipes-nav-preorder {
    background-color: #006d26 !important;
    color: #fff !important; /* Assure que le texte reste blanc */
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-align: center;
    z-index: 1;

  }
  
  /* Mobile Styles */
  @media screen and (max-width: 600px) {
    .recipes-nav {
      padding: 0.5rem; /* Réduit l'espace sur mobile */
      flex-direction: row; /* Affiche les éléments en ligne sur mobile */
      justify-content: space-around; /* Centré horizontalement */
    }
  
    .recipes-nav-links {
      width: 100%;
      margin-top: 0;
      padding: 0.5rem;
    }
  }
  
  /* Tablet Styles */
  @media screen and (min-width: 601px) and (max-width: 1024px) {
    .recipes-nav {
      padding: 1rem; /* Espacement cohérent pour tablettes */
    }
  
    .recipes-nav-links {
      width: 75%;
    }
  }
  