@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.popup-content {
    background-color: #fff;
    z-index: 9999;
    position: fixed !important;
    right: 0;
    top: 0;
    width: 400px !important;
    height: 100vh;
    padding: 2rem;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: .5s ease-out 0s 1 slideInFromRight;
}

.popup-create-content {
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.popup-create-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    outline: rgb(50, 89, 62);
}

.create-product-label {
    text-align: left !important;
    width: 300px !important;
    margin-bottom: -.7rem;
}


.popup-create-name,
.popup-create-totalQty,
.popup-create-unit,
.popup-create-price {

    border: 1px solid #DADADA;
    border-radius: 5px;
    padding: 1rem !important;
    width: 300px !important;
    font-family: 'Montserrat', sans-serif;
}



.popup-actions {
    margin-top: 1rem;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.popup-actions button {
    width: 100%;
    margin: 0;
}

@media screen and (max-width: 600px) {
    .popup-content {
        width: 100% !important;

    }





}