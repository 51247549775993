.products-table-action {
    width: 100%;
    height: 100px;
}

.search-bar {
    width: 300px;
    padding: 1rem;
    border: 1px solid #DADADA;
    border-radius: 5px;
}