@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500;600&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  background-color: #ffffff;
}

.app {
  height: 100%;
}

.delivery-zones {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 0.1rem;
  overflow-y: auto;
}

.text-container {
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Place les éléments en haut */
  gap: 1rem; /* Ajoute de l'espace entre les éléments */
  width: 100%;
}

.image-container {
  max-height: 600px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.btn-container {
  width: 100%;
  margin-top: 1rem;
  text-align: center;
}

/* Mobile CSS */
@media screen and (max-width: 600px) {
  .delivery-zones {
    grid-template-columns: 1fr;
    padding: 0.1rem;
  }

  .image-container {
    max-height: 300px;
    margin-top: 4rem;
  }

  .type-of-delivery-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centre horizontalement */
    gap: 1rem; /* Ajoute de l'espace entre les cartes */
    margin-top: 0.1rem; /* Espace supplémentaire pour éviter l'écrasement */
  }
  
  .delivery-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.1rem;
  }
  
  .delivery-card-title {
    text-align: center;
    margin-bottom: 1rem;
  }
}

/* Tablet CSS */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .delivery-zones {
    grid-template-columns: 1fr;
    padding-bottom: 0.1rem;
  }

  .image-container {
    max-height: 400px;
  }
}