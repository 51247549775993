.navbar-dropdown {
    width: 170px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10000;
}

.navbar-icon {
    position: relative;
    cursor: pointer;
}

.navbar-dropdown a {
    text-align: left;
    background-color: #fff;
    display: block;
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    border: none;
    font-family: 'Montserrat', sans-serif;
}

.navbar-dropdown a:hover {
    text-align: left;
    transition: all .4s ease-in-out;
    background-color: rgb(28, 65, 39);
    color: #fff;
    border: none;
    cursor: pointer;
}

.dropdown-mobile {
    display: none;
}

button.logout-btn {
    width: 100%;
    text-align: left;
    background-color: #fff;
    display: block;
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    border: none;
    font-family: 'Montserrat', sans-serif;
}

button.logout-btn:hover {
    text-align: left;
    transition: all .4s ease-in-out;
    background-color: rgb(28, 65, 39);
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
}

/*Mobile CSS*/
@media screen and (max-width: 600px) {
    .dropdown-mobile {
        display: block;
        width: 100%;
        height: 110px;
    }

    .dropdown-container {
        width: 100%;
        height: 100px;
        border-radius: 0;
    }

    .dropdown-desktop {
        display: none;
    }

    .navbar-dropdown {
        text-align: center;
        position: absolute;
        top: 10%;
        left: 50%;
        width: 100%;
        display: block;
        box-shadow: none;
        border-bottom: .5px solid #ddd;
        background-color: transparent;
        border-radius: 0;
        z-index: 10000;
    }

    .navbar-icon {
        position: unset;
    }

}

/* Tablet CSS */
@media screen and (min-width: 601px) and (max-width:1024px) {
    .navbar-dropdown {
        width: 170px;
        z-index: 9999;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-80%);
        background-color: white;
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 10000;
    }

}