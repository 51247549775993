/* Navbar Container */
.nav-container {
  width: 100%;
  max-width: 100vh;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0;
  background-color: white;
  box-sizing: border-box;
  z-index: 1001;
}

/* Navbar */
.navbar {
  position: fixed; /* Fixée en haut du viewport */
  top: 0;
  width: 100%;
  z-index: 1002; /* Priorité élevée pour être au-dessus */
  max-width: 100%;
  background-color: white; /* Assurez que le fond soit opaque */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 60px; /* Hauteur fixe de la navbar */
  border-bottom: 0.5px solid #ddd;
}

/* Navbar Logo */
.navbar-logo {
  width: 40px; /* Taille fixe du logo */
  cursor: pointer;
}

/* Navbar Left Section */
.navbar-left {
  flex: 1;
  display: flex;
  align-items: center;
}

/* Navbar Right Section */
.navbar-right {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ajuste automatiquement l'espace entre les éléments */
}

/* Dropdown */
.navbar-dropdown {
  position: absolute;
  transform: translateX(-50%);
  width: auto; /* Largeur fixe pour tous les appareils */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 10000;
}

/* Responsive Navbar */
@media screen and (max-width: 1024px) { /* Gère les tablettes et mobiles ensemble */
  .navbar {
    flex-direction: row; /* aligne les éléments horizontalement sur mobile */
    padding: 1rem;
    height: 60px; /* Hauteur ajustée selon le contenu */
    z-index: 9999;
  }

  .navbar-left,
  .navbar-right {
    flex-direction: row;
    width: 100%;
    justify-content: space-between; /* Répartit l'espace uniformément */
  }

  .navbar-icon-cart {
    display: inline-block; /* Assure que l'icône du panier est toujours visible */
    padding: 1rem;
  }

  .dropdown-mobile-container.open {
    position: fixed;
    top: 60px; /* Démarre juste en dessous de la navbar */
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent pour le focus */
    z-index: 300;
  }

  .dropdown-mobile-cart {
    background-color: white;
    border-top: 1px solid #ddd;
    max-height: 80%; /* Hauteur maximale pour le scroll interne */
    overflow-y: auto;
  }

}

