/* Side-Cart */

.side-cart-container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px); /* Hauteur ajustée pour exclure la navbar */
    position: fixed;
    background-color: white;
}

.side-cart-menu {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.side-cart-title {
    margin-bottom: 2rem;
    text-align: center;
}

.side-cart-recap {
    margin: 1rem 0.5rem;
    padding: 0.5rem 0; /* Espacement interne pour le récapitulatif */
    display: flex;
    flex-direction: column; /* Colonne pour aligner correctement les éléments */
    align-items: flex-start; /* Aligne le contenu à gauche */
    border-top: 1px solid #ddd; /* Ligne de séparation visuelle */
}

.side-cart-total-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem; /* Espacement sous le titre */
}

.side-cart-menus {
    display: flex;
    flex-direction: column; /* Organise les éléments verticalement */
    align-items: flex-start; /* Aligne le contenu à gauche */
    margin-bottom: 1rem; /* Espacement entre les sections des menus */
    line-height: 1.5; /* Espacement entre les lignes pour une meilleure lisibilité */
}

.side-cart-menus span {
    display: block; /* Chaque élément occupe une ligne distincte */
}

.side-cart-total {
    display: flex;
    justify-content: space-between; /* Place le libellé et le montant de part et d'autre */
    width: 100%; /* Prend toute la largeur du conteneur */
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem; /* Espacement entre les menus et le total */
}

.side-cart-action {
    margin-top: 0.5rem;
    text-align: center;
}

/* Cart Item */

.cart-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Équilibre l'espace entre les éléments */
    padding: 1rem 0;
    border-bottom: 0.5px solid #ddd;
    width: 100%;
}

.side-cart-items-list {
    overflow-y: auto;
    max-height: calc(100vh - 300px); /* Hauteur maximale pour les articles */
}

.item-img-container {
    width: 100px;
    height: 60px;
    overflow: hidden;
    margin-right: 1rem;
    flex-shrink: 0;
    border-radius: 8px;
}

.item-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-text {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.item-details {
    display: flex;
    flex-direction: column;
}

.item-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.item-price {
    font-weight: 600;
}

.item-quantity {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.item-qty-icon {
    cursor: pointer;
}

.item-delete-icon {
    color: red;
    cursor: pointer;
    margin-left: 1rem;
}

/* Mobile CSS */

@media screen and (max-width: 600px) {
    .side-cart-container {
        border-top: 0.5px solid #ddd;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }

    .side-cart-title {
        font-size: 1.2rem;
        text-align: center;
    }

    .side-cart-recap {
        margin: 0.5rem 0;
        padding: 0.5rem 0;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .cart-item-container {
        flex-direction: row;
        align-items: flex-start;
        padding: 0.5rem 0;
        gap: 1rem;
    }

    .item-img-container {
        width: 80px;
        height: 80px;
    }

    .item-text {
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
    }

    .item-name {
        font-size: 0.9rem;
        margin-bottom: 0.3rem;
    }

    .item-price {
        font-size: 0.9rem;
    }

    .item-quantity {
        margin-top: 0.3rem;
    }
}
