.menu {
    display: flex-start;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    min-height: 100vh;
}

.menu h1 {
    margin-bottom: 0rem;
    font-variant-caps: all-small-caps;
    font-size: 35px;
}

.menu-add {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.menu-total-price {
    margin-top: 0;
    margin-bottom: 2rem;
}

.add-recipe-icon {
    color: #006d26;
    cursor: pointer;
}

.recipes_list {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.menu-recipe-card {
    flex: 0 1 calc(33.333% - 1rem);
    max-width: 100%;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.divison {
    width: 100%;
    border-top: 1px solid #DADADA;
    margin-bottom: 1rem;
}

.menu-actions {
    display: flex; /* Force un affichage côte à côte */
    flex-direction: row;
    width: auto; /* Supprime une éventuelle largeur forcée */
    max-width: none; /* Supprime toute restriction */
    text-align: center;
    gap: 0.5rem;
}

/* ________________________ SHOPPING LISTS _____________________ */

.shopping-list-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 6rem;
    height: 100%;
}

.shopping-list-back-link {
    margin: 1rem 0;
}

.shopping-list-header,
.shopping-list-content {
    margin: 1rem;
}

.insights {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.shopping-list-tables {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    max-height: 100%;
}

.MuiCard-root {
    max-width: 100%;
    width: 100%; /* allows flexible resizing */
    box-sizing: border-box;
}

.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.card-calories-score, .card-calories, .MuiTypography-root {
    width: 100%;
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 600px) {
    .menu {
        width: 100%;
        padding: 0;
    }

    .menu-recipe-card {
        flex: 1 1 100%;
        max-width: 100%;
        margin-bottom: 1rem;
    }

    .divison {
        display: none;
    }

    .recipes_list {
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
    }

    .shopping-list-content {
        flex-direction: column;
    }

    .insights {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 1rem;
    }
}

/* Tablet CSS */
@media screen and (min-width: 601px) and (max-width: 1024px) {
    .recipes_list {
        width: 100%;
        display: flex;
        gap: 0.5rem;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .menu-recipe-card {
        flex: 1;
        max-width: 100%;
        margin-bottom: 1rem;
    }

    .shopping-list-content {
        flex-direction: column;
    }

    .insights {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 1rem;
    }
}
